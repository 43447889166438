import React, { useState, useEffect } from "react";

import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

import extra_info_card from "../../img/About/extra_info_header.png";
import extra_info_card_front from "../../img/About/extra_info.png";
import socials_card from "../../img/About/socials.png";
import main_card from "../../img/About/no_eye.png";
import eye_ball from "../../img/About/eye_ball_long.png";

import insta from "../../img/About/icons/insta.png";
import facebook from "../../img/About/icons/facebook.png";
import etsy from "../../img/About/icons/etsy.png";
import tiktok from "../../img/About/icons/tiktok.png";

import "./About.css";
import { AnimatePresence, motion } from "framer-motion";

export default function About() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    particlesJS("particles-js", {
      particles: {
        number: {
          value: 355,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.48927153781200905,
          random: false,
          anim: {
            enable: true,
            speed: 0.2,
            opacity_min: 0,
            sync: false,
          },
        },
        size: {
          value: 2,
          random: true,
          anim: {
            enable: true,
            speed: 2,
            size_min: 0,
            sync: false,
          },
        },
        line_linked: {
          enable: false,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 0.2,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "bubble",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 83.91608391608392,
            size: 1,
            duration: 3,
            opacity: 1,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  }, []);

  return (
    <motion.div style={{"overflow":"hiddden"}}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="About">
        <div
          id="particles-js"
          style={{
            position: "absolute",
            height: "99.6vh",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <main className="mainContent">
          <div className="left">
            <div className="mainCardContainer">
              <img
                src={eye_ball}
                alt="eye ball"
                className="eyeball about-img"
              />
              <img
                src={main_card}
                alt="hours card"
                className="leftCard mainCard about-img"
              />

              <h1 className="mainHeader about-header">Mystic and Magick</h1>
              <p className="mainText about-p">
                Missy (Owner of Mystic & Magick) strives to bring the power of
                Crystals to the world by promoting the education and benefits of
                Crystal Healing and Magick.
              </p>
              <p className="mobileText about-p">
                Missy, a Certified Crystal Healer, Energy Worker and Intiated
                Priestess, can help you discover the right energy, and Crystal,
                to help you along your sacred path.
              </p>
            </div>
          </div>

          <div className="right">
            <div className="flipperContainer">
              <div className="flipper">
                <div className="back extraInfo">
                  <img
                    src={extra_info_card}
                    alt="extra info card"
                    className="extraInfoCard rightCard about-img"
                  />
                  <h1 className="extraInfoHeader about-header">
                    Join in on the Magick!
                  </h1>
                  <p className="extraInfoText about-p">
                    Missy, a Certified Crystal Healer, Energy Worker and
                    Intiated Priestess, can help you discover the right energy,
                    and Crystal, to help you along your sacred path.
                    <br />
                    <br />
                    Located in Kingston, Ontario, Mystic & Magick hosts both
                    Online and In person Crystal Shows and Events.
                    <br />
                    <br />
                    You can also shop directly through Mystic & Magick's Etsy
                    Shop. Be sure to check our Facebook for where you can find
                    us next!
                  </p>
                </div>
                <div className="front extraInfo">
                  <img
                    src={extra_info_card_front}
                    alt="extra info card"
                    className="extraInfoCard rightCard about-img"
                  />
                  <h1 className="hover about-hover">Hover Me</h1>
                </div>
              </div>
            </div>

            <div className="socialsCardContainer">
              <img
                src={socials_card}
                alt="socials card"
                className="socialsCard rightCard about-img"
              />
              <h1 className="socialsHeader about-header">Socials</h1>
              <div className="socialsIcons">
                <a
                  href="https://www.instagram.com/mystic_and_magick/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={insta}
                    alt="instagram icon"
                    className="icon icon-one about-img"
                  />
                </a>
                <a
                  href="https://www.facebook.com/mysticandmagick/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebook}
                    alt="facebook icon"
                    className="icon icon-two about-img"
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@MysticandMagick"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={tiktok}
                    alt="tikok icon"
                    className="icon icon-three about-img"
                  />
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </motion.div>
  );
}
