import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
// import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import { AnimatePresence, motion } from "framer-motion";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // Decided to implement Navbar into HeroSection to fix a visual bug occuring with Nav.
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Sidebar isOpen={isOpen} toggle={toggle} />
      {/* <Navbar toggle={toggle} /> */}
      <HeroSection toggle={toggle} />
    </motion.div>
  );
};

export default Home;
