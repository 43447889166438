import React, { useEffect } from "react";
import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  AniArrow,
  SvgWrapper,
  H1Bottom,
  PBottom,
  PicTest,
  SvgFlexWrapper,
  PicDiv,
} from "./HeroElements";
import { animateScroll as scroll } from "react-scroll";
import Navbar from "../Navbar";
import cover from "../../img/homepic.png";

// VideoBg has additional properties that I won't be using since
// the background animations are all css.
const HeroSection = ({ toggle }) => {
  // toggleHomeScroll function on the moving arrows that is no longer working.
  const toggleHomeScroll = () => {
    console.log("toggleHomeScroll was called");
    scroll.scrollToBottom();
  };

  // Javascript function that animates particles
  // I've tried migrating the particlesJS function to the particles.js file
  // to make this file less chaotic but no dice. Maybe I'll figure it out later.

  // FOR PARTICLE BACKGROUND, COPY LINES 32 to 144, ADD THE useEfect TO YOUR
  // JS FILE DISPLAYING THE PAGE, AND MAKE SURE YOU ADD LINES 190-199 AT THE END
  // OF YOUR DISPLAY DIV's
  useEffect(() => {
    // eslint-disable-next-line no-undef
    particlesJS("particles-js", {
      particles: {
        number: {
          value: 355,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.48927153781200905,
          random: false,
          anim: {
            enable: true,
            speed: 0.2,
            opacity_min: 0,
            sync: false,
          },
        },
        size: {
          value: 2,
          random: true,
          anim: {
            enable: true,
            speed: 2,
            size_min: 0,
            sync: false,
          },
        },
        line_linked: {
          enable: false,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 0.2,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "bubble",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 83.91608391608392,
            size: 1,
            duration: 3,
            opacity: 1,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  }, []);

  return (
    <HeroContainer>
      <div
        id="particles-js"
        style={{
          position: "absolute",
          height: "calc(200vh + 80px)",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
      <Navbar toggle={toggle} />
      <HeroContent>
        <HeroH1>MYSTIC & MAGICK</HeroH1>
        <HeroP>
          A place where earth's Magickal Minerals gather, offering energy &
          beauty to those who wish to work with their Magick.
        </HeroP>
        <HeroBtnWrapper>
          <AniArrow />
          <AniArrow />
          <AniArrow />
        </HeroBtnWrapper>
      </HeroContent>
      <HeroContent style={{ position: "relative" }}>
        <PicTest style={{ position: "absolute" }}>
          <source
            style={{
              position: "absolute",
              visibility: "hidden",
              display: "none",
            }}
            srcset={require("../../img/eye.svg").default}
            media="(max-width: 768px)"
          />
          <img
            src={require("../../img/border.svg").default}
            alt=" "
            style={{
              maxWidth: 1200,
              width: "min(calc(100% - 24*2px), 1100px)",
            }}
          />
        </PicTest>
        <SvgWrapper>
          <SvgFlexWrapper>
            <H1Bottom>~ Welcome ~</H1Bottom>
            <PBottom>
              Whether it is a crystal to bring more love into your life, or a
              decorative treasure for your home, Mystic & Magick carries a
              variety of Crystals, Jewelry and Meta-Physical Tools for all your
              spiritual life.
            </PBottom>
          </SvgFlexWrapper>
          <div style={{ flex: "4 4 0" }}></div>
          <PicDiv style={{ flex: "3.5 3.5 0" }}>
            <img src={cover} style={{ width: "100%" }} alt="rightSideImage" />
          </PicDiv>
        </SvgWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
