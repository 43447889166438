import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import Scroll from "../../img/scroll.png";
import MoonContainer from "../../components/CrescentMoonContainer/CrescentMoonContainer";
import Popup from "../../components/Popup/Popup";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { AnimatePresence, motion } from "framer-motion";

// 3 ID values are needed from EmailJS account for the contact from to send emails
const SERVICE_ID = "service_mfuxy5n"; // Identifies which email service to use
const TEMPLATE_ID = "template_mtfrrgd"; // Identifies which email template to use
const USER_ID = "m_ohCH9JLU3IOLJbu"; // Identifies EmailJS user account

export default function Contact() {
  // To send email using EmailJS
  const form = useRef();
  const [buttonPopup, setButtonPopup] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    setButtonPopup(true); // Notifies user that email sent successfully
  };

  // FOR HAMBURGER MENU SIDEBAR
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // FOR PARTICLE BACKGROUND
  useEffect(() => {
    // eslint-disable-next-line no-undef
    particlesJS("particles-js", {
      particles: {
        number: {
          value: 355,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.48927153781200905,
          random: false,
          anim: {
            enable: true,
            speed: 0.2,
            opacity_min: 0,
            sync: false,
          },
        },
        size: {
          value: 2,
          random: true,
          anim: {
            enable: true,
            speed: 2,
            size_min: 0,
            sync: false,
          },
        },
        line_linked: {
          enable: false,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 0.2,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "bubble",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 83.91608391608392,
            size: 1,
            duration: 3,
            opacity: 1,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="contact-us">
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />

        <div className="content">
          <Popup trigger={buttonPopup} setTrigger={setButtonPopup} />

          <div className="left-side">
            <MoonContainer />
          </div>

          <div className="right-side">
            <div className="scroll">
              <img
                src={Scroll}
                className="scroll-handle"
                id="top-scroll"
                alt="Scroll handle top"
                draggable="false"
              />
              <div className="animation">
                <div className="form">
                  <form ref={form} onSubmit={sendEmail}>
                    <label>Name</label>
                    <input type="text" name="user_name" required />
                    <label>Email</label>
                    <input type="email" name="user_email" required />
                    <label>Phone (optional)</label>
                    <input type="tel" name="user_phone" />
                    <label>Message</label>
                    <textarea name="message" required />
                    <input
                      type="submit"
                      name="submit"
                      className="button"
                      value="Send"
                    />
                  </form>
                </div>
                <img
                  src={Scroll}
                  className="scroll-handle"
                  id="bottom-scroll"
                  alt="Scroll handle bottom"
                  draggable="false"
                />
              </div>
            </div>
          </div>
          <div
            id="particles-js"
            style={{
              position: "absolute",
              height: "99.6vh",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        </div>
      </div>
    </motion.div>
  );
}
