// Attempting to use styled components for this. It allows for if-else statements
// within the styling sheets. Can migrate to normal css later on if issues arise.
import { Link as LinkR } from "react-router-dom";
import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

// Full navbar container. Consider changing height to relative proportions?
// Not sure of the etiquette really.
export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#430CA0" : "transparent")};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

// Extra styling. Could incorportate it into just Nav but I'm
// afraid it'll screw up child divs.
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: right;
  height: 80px;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

// NavLogo. Basically the moon png. Remember to finish properly routing it to home page.
export const NavLogo = styled(LinkR)`
  color: pink;
  justify-self: left;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  text-decoration: none;
`;

// Hamburger Menu Icon
// margin-top: 2px is added to offset the logo a bit down as
// the actual png is not evenly (square) sized
export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    margin-right: -35px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ebc37d;
    margin-top: 2px;
  }
`;

// The actual navbar ul that holds all individual links.
// Current max-width to transition to hamburger menu is
// set to 768px. Should check with team later on if this is proper.
export const NavMenu = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  font-weight: bold;
  font-size: 1.2em;
  /* margin-right: -22px; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

// Each individual link on the navbar (home, about, etc.)
export const NavItem = styled.li`
  height: 80px;
  display: flex;
`;

// Using styled(LinkS) to allow for NavBar to scroll down to section
// of single-page website. Since this is a multi-page website, this isn't
// needed (yet). So remove this later!
export const NavLinks = styled(LinkR)`
  color: #ebc37d;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100;
  cursor: pointer;

  &:hover {
    color: white;
    transition: 0.2s ease-in-out;
  }

  &.active {
    border-bottom: 3px solid #fff;
  }
`;
