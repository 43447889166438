import React from "react";
import "./Popup.css";
import MoonPhases from "../../img/moon-phases.png";

//Email confirmation popup

export default function Popup(props) {
  return props.trigger ? (
    <div className="popup">
      <div className="popup-inner">
        <p className="email-sent">Email Sent!</p>
        <img
          src={MoonPhases}
          id="moon-phases"
          alt="Moon phases"
          draggable="false"
        />
        <p className="popup-text">
          Mystic and Magick will get back to you shortly.
        </p>
        <button className="close-btn" onClick={() => props.setTrigger(false)}>
          OK
        </button>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}
