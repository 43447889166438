import styled from "styled-components";

// Overall wrapper
export const HeroContainer = styled.div`
  // CSS Snap Properties
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  max-height: 100vh;
  padding: 0 30px;

  // Gradient Animation. Need to tweak later.
  // FOR GRADIENT BACKGROUND, COPY AND ADD LINES 14 to 34 ON YOUR BG WRAPPER
  background: linear-gradient(45deg, #830db4, #5010a4, #830db4);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  position: relative;

  /*
  Not sure the use of these below. Will keep for now.
  z-index: 1;
  */

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

// TEMPORARILY adding 80px of padding to fix issue where Navbar overlays HeroContainer
export const HeroContent = styled.div`
  scroll-snap-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 24px;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 80px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 50px;
  }

  @media screen and (max-width: 480px) {
    font-size: 36px;
  }
`;

export const HeroP = styled.p`
  margin-top: 44px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

// This is the button wrapper. Currently doesn't use scroll.scrollToBottom() function,
// says that target Element not found.
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  min-height: 75px;
  min-width: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// ANIMATED ARROW CSS BLOCKS

export const AniArrow = styled.div`
  position: absolute;
  width: 100px;
  height: 3px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;

  &:first-child {
    animation: move 3s ease-out 1s infinite;
  }

  &:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
  }

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
    left: 0;
    transform: skew(0deg, 30deg);
  }

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }

  @keyframes move {
    25% {
      opacity: 1;
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }

  @keyframes pulse {
    to {
      opacity: 1;
    }
  }
`;

// Div used for second part of home page
export const SvgWrapper = styled.div`
  position: absolute;
  width: min(calc(100% - 24 * 4px), 1050px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 400px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75%;
    width: 90%;
  }
`;

export const SvgFlexWrapper = styled.div`
  flex: 3 3 0;
  margin-left: 15px;

  @media screen and (max-width: 758px) {
    margin-left: 0px;
  }
`;

export const H1Bottom = styled.h1`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap");
  color: #fff;
  font-size: 3vw;
  text-align: left;
  margin-left: 30px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    text-align: center;
    margin-left: 0px;
  }

  @media screen and (min-width: 1450px) {
    font-size: 40px;
  }
`;

export const PBottom = styled.p`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap");
  margin-top: 5px;
  color: #fff;
  font-size: 1.5vw;
  text-align: left;
  max-width: 600px;
  margin-left: 30px;
  letter-spacing: 0.02em;

  @media screen and (max-width: 768px) {
    font-size: 15px;
    text-align: center;
    margin-left: 0px;
    letter-spacing: 0.04em;
    line-height: 28px;
    margin-top: 10px;
  }
  @media screen and (min-width: 1450px) {
    font-size: 20px;
  }
`;

export const PicTest = styled.picture`
  scroll-snap-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 24px;
`;

export const PicDiv = styled.div`
  margin-right: 30px;

  @media screen and (max-width: 768px) {
    margin-right: 0px;
    width: 92%;
    flex: 3.5 3.5 0;
  }
`;
