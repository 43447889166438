import React from "react";
import Container from "../../img/crescent-moon-container.png";
import "./CrescentMoonContainer.css";

// Contact us title and text

export default function CrescentMoonContainer() {
  return (
    <div>
      <div className="crescent-moon-container">
        <img
          src={Container}
          className="moon-container"
          alt="Purple crescent moon container"
          draggable="false"
        />

        <div className="moon-container-text">
          <h1 className="title">Get In Touch</h1>
          <p className="text">
            Feel free to send a message to Mystic & Magick regarding any
            questions you might have!
          </p>
        </div>
      </div>
    </div>
  );
}
